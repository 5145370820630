.VierGewinnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-color: #282c34;
  width: 100%; 
  max-width: 600px; 
  margin: 0 auto;
  position: relative; 
}
.reihe {
  display: flex;
}

.span {
  color: white;
  margin: auto;
}

.zelle {
  width: 50px;
  height: 50px;
  border: 1px solid #61dafb;
}

.spieler-0 {
  background-color: #282c34;
}

.spieler-1 {
  background-color: #2a49f8;
}

.spieler-2 {
  background-color: #f71919;
}
