.game {
  position: relative;
  width: 100%; 
  max-width: 800px; 
  height: 80vh;
  border: 2px solid #000;
  overflow: hidden;
  margin: 0 auto; 
}

.mario {
  position: absolute;
  bottom: 50px;
  left: 50px;
  width: 40px;
  height: 60px;
  background-color: red; 
}

.ground {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: green;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.control-btn {
  width: 60px;
  height: 60px;
  background-color: #ddd;
  border: none;
  border-radius: 50%;
  font-size: 24px;
}
